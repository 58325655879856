import { bindRippleEffect } from "./components/ui/rippleEffect";
import { search } from "./components/search/search";
import { handleNavScroll } from "./utility";

console.log('hi from vesti.rs')

document.addEventListener('scroll', handleNavScroll);
// banner
// const bannerMobileElement = $('.banner_mobile');
// setTimeout(() => {
//     $(bannerMobileElement).fadeIn(500)
// }, 3000);
// $('.close_banner_mobile_icon').on('click', () => $(bannerMobileElement).fadeOut(500))

// cookies policy
const acceptCookiesELement = $('.cookie_buttons_container');
$(acceptCookiesELement).hide();
// $('.cookie_button_allow').on('click', () => $(acceptCookiesELement).fadeOut(300));
// $('.cookie_button_deny').on('click', () => $(acceptCookiesELement).fadeOut(300));

// search
const searchButtonElement = $('.search_button');
$(searchButtonElement).on('click', search);

bindRippleEffect()


// remove loader
$('#main').fadeOut(1);
setTimeout(() => {
    $('.my_preloader').fadeOut(300);
    setTimeout(() => {
        $('#main').fadeIn(300);
        if($('.singleNews').length > 0) {
            $('.singleNews').addClass('flipIn');
        } 
        // else {
        //     $('body').addClass('scaleIn');
        // }

        // add transitions to popup
        setTimeout(() => {
            if($('.singleNews')) {
                $('.singleNews').removeClass('flipIn');
            } 
            // else {
            //     $('body').removeClass('scaleIn');
            // }
            $('.popup-overlay').css('transition', 'all 500ms ease-in-out')
            $('.popup-overlay').css('transition-property', 'opacity, transform')
        }, 600);
    }, 300);
}, 300);
