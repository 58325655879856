import { hidePopup, showPopup } from "../ui/popup";
import { bindRippleEffectSingle } from "../ui/rippleEffect";

export const search = () => {
    const searchHtml = `
        <div class="d-flex search_container">
            <input type="text" placeholder="Pretraga" class="search_input ripple_effect" />
            <button class="search_button_submit ripple_effect"><i class='bx bx-search'></i></button>
        </div>
    `;
    
    let searchElement = document.createElement('div');
    searchElement.innerHTML = searchHtml;
    searchElement = searchElement.children[0];

    const handleSearchSubmit = e => {
        console.log('Pretraga kliknuta')
        hidePopup()
    }
    const searchSubmitButtonElement = searchElement.querySelector('.search_button_submit');
    searchSubmitButtonElement.addEventListener('click', handleSearchSubmit)

    bindRippleEffectSingle(searchElement);

    showPopup(searchElement);
}