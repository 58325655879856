// import $ from 'jquery';
// window.jQuery = $;
// window.$ = $;

import ee from 'event-emitter';

const emitter = new ee();
// bind events
export const showPopup = (content) => {
    emitter.emit('showpopup', content);
}
export const hidePopup = () => {
    emitter.emit('hidepopup');
}
// handle events
emitter.on('showpopup', (content) => {
    state.show = true;
    state.content = content;
    show()
});
emitter.on('hidepopup', () => {
    hide();
});
$('.popup-overlay').on('click', (event) => {
    if(event.target.classList.contains('popup-overlay') || event.target.classList.contains('popup-div'))
    hide()
    
    if(event.target.closest('.popup-close'))
    hide()
});

const hide = () => {
    state.show = false;
    $('.popup-overlay').removeClass('show-popup')
    setTimeout(() => {
        state.content = null;
        $('body').css('overflow', 'auto');
        $('body').css('padding-right', 'unset');
        $('.popup-div').html(null)
    }, 300);
};
const show = () => {
    $('.popup-overlay').addClass('show-popup');
    $('.popup-div').html(state.content);
    $('body').css('overflow', 'hidden');
    $('body').css('padding-right', '8px');
}

const state = {
    show: false,
    content: null
}