
const createRipple = event => {
    const button = event.currentTarget;
    const circle = document.createElement("span");
    const diameter = Math.max(button.clientWidth, button.clientHeight);
    const radius = diameter / 2;

    circle.style.width = circle.style.height = `${diameter}px`;
    circle.style.left = `${event.clientX - (button.getBoundingClientRect().left + radius)}px`;
    circle.style.top = `${event.clientY - (button.getBoundingClientRect().top + radius)}px`;
    circle.classList.add("ripple"); 

    const oldRipple = button.querySelector('.ripple');

    if(oldRipple) {
        oldRipple.remove();
    }

    button.appendChild(circle);

    const newRipple = button.querySelector('.ripple');

    if(newRipple) {
        setTimeout(() => {
            newRipple.remove();
        }, 600);
    }
}
export const bindRippleEffect = () => {
    const buttons = document.querySelectorAll('.ripple_effect');
    for (const button of buttons) {
        button.removeEventListener("click", createRipple);
        button.addEventListener("click", createRipple);
    }
}
export const bindRippleEffectSingle = (element) => {
    const buttons = element.querySelectorAll('.ripple_effect');
    for (const button of buttons) {
        button.removeEventListener("click", createRipple);
        button.addEventListener("click", createRipple);
    }
}