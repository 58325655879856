import { stickyHeaderElement } from "./components/ui/domElements";

// toggle navbar
let previousScrollTop = window.pageYOffset;
export const handleNavScroll = e => {
    const currentScrollTop = window.scrollY;

    if(currentScrollTop > previousScrollTop) {
        stickyHeaderElement.classList.add('hideNav')
    } 
    if (currentScrollTop < previousScrollTop) {
        stickyHeaderElement.classList.remove('hideNav')
    }
    
    previousScrollTop = window.scrollY;
}